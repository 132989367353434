import React from 'react'

const IndexPage = () => (
        <div className="container text-center">
          <div className="row">
            <div className="col-md-8 mx-auto">
            </div>
          </div>
        </div>

)

export default IndexPage
